var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DisplayContainer',{staticStyle:{"background-color":"transparent"},attrs:{"isSubcomponent":"","label":"Session Lookup"}},[_c('div',[_c('div',{staticClass:"input-row-auto",staticStyle:{"max-width":"850px","grid-template-columns":"40% 40% 20%"}},[_c('v-text-field',{ref:"username",attrs:{"label":"Username","placeholder":"username@goetel","clearable":"","autofocus":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{ref:"ipv4",attrs:{"label":"IPv4","placeholder":"127.0.0.1","clearable":"","rules":[_vm.ipv4Rule]},model:{value:(_vm.ipv4),callback:function ($$v) {_vm.ipv4=$$v},expression:"ipv4"}}),_c('v-text-field',{ref:"port",attrs:{"label":"Port","type":"number","min":"1","max":"65535","placeholder":"1 - 65535","rules":[_vm.portRule]},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}})],1),_c('div',{staticClass:"input-row-auto",staticStyle:{"max-width":"850px","grid-template-columns":"50% 50%"}},[_c('v-text-field',{ref:"ipv6",attrs:{"label":"IPv6","placeholder":"::1","clearable":"","rules":[_vm.ipv6Rule]},model:{value:(_vm.ipv6),callback:function ($$v) {_vm.ipv6=$$v},expression:"ipv6"}}),_c('v-menu',{ref:"datePickMenu",attrs:{"close-on-content-click":false,"return-value":_vm.dateRange,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateRange=$event},"update:return-value":function($event){_vm.dateRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Datum","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePickMenu),callback:function ($$v) {_vm.datePickMenu=$$v},expression:"datePickMenu"}},[_c('v-date-picker',{attrs:{"max":new Date().toISOString(),"show-adjacent-months":"","range":"","no-title":"","scrollable":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.datePickMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.datePickMenu.save(_vm.dateRange)}}},[_vm._v(" OK ")])],1)],1)],1)]),_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"acctsessionid","headers":_vm.tableHeaders,"items":_vm.tableFilteredSessions,"options":_vm.tableOptions,"server-items-length":_vm.tableTotalFilteredSessions,"loading":_vm.tableLoading,"dense":"","single-select":true,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 25, 50]
      }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.sessionLockId",fn:function(ref){
      var item = ref.item;
return [(item.sessionLockId !== 0)?_c('v-icon',{attrs:{"title":"Diese Session ist bereits gesperrt","color":"warning"}},[_vm._v(" mdi-lock-outline ")]):_c('v-icon',{attrs:{"color":"success"},on:{"click":function($event){$event.stopPropagation();_vm.lockSession=item; _vm.lockSessionDialog = true}}},[_vm._v(" mdi-lock-open-variant-outline ")])]}},{key:"item.acctStartTime",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatSessionDateTime(item.acctStartTime))+" ")]}},{key:"item.acctStopTime",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatSessionDateTime(item.acctStopTime))+" ")]}}],null,true)})],1),(_vm.lockSession !== null)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.lockSessionDialog),callback:function ($$v) {_vm.lockSessionDialog=$$v},expression:"lockSessionDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Session "+_vm._s(_vm.lockSession.radacctid)+" sperren ")]),_c('v-card-text',{staticClass:"mt-4"},[_c('v-text-field',{attrs:{"filled":"","label":"Session ID","type":"number","disabled":""},model:{value:(_vm.lockSession.radacctid),callback:function ($$v) {_vm.$set(_vm.lockSession, "radacctid", $$v)},expression:"lockSession.radacctid"}}),_c('v-text-field',{attrs:{"filled":"","counter":"7","label":"ORTS Ticket ID","hint":"Die ID aus der URL nach 'TicketID='","type":"number"},model:{value:(_vm.lockSessionData.ticketId),callback:function ($$v) {_vm.$set(_vm.lockSessionData, "ticketId", $$v)},expression:"lockSessionData.ticketId"}}),_c('v-textarea',{attrs:{"label":"Kommentar","maxlength":"2000"},model:{value:(_vm.lockSessionData.comment),callback:function ($$v) {_vm.$set(_vm.lockSessionData, "comment", $$v)},expression:"lockSessionData.comment"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"warning","text":"","loading":_vm.lockSessionLoading},on:{"click":function($event){$event.stopPropagation();return _vm.lockSessionRequest.apply(null, arguments)}}},[_vm._v(" Sperren ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.lockSession = null; _vm.lockSessionDialog = false}}},[_vm._v(" Abbrechen ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }