<template>
  <div>
    <div class="input-row">
      <div>
        <div class="label">
          Nummer
        </div>
        <div>
          {{
            `+${numberInfo.countryCode} ${numberInfo.areaCode} ${numberInfo.numberPart}`
          }}
        </div>
      </div>
      <div>
        <div class="label">
          Aktueller Carrier
        </div>
        <div>
          {{ numberInfo.carrier !== null ? numberInfo.carrier.name : '-' }}
        </div>
      </div>
    </div>
    <div class="input-row">
      <div>
        <div class="label">
          Portierungskennung
        </div>
        <div>
          {{ numberInfo.carrier !== null ? numberInfo.carrier.routingId : '-' }}
        </div>
      </div>
      <div>
        <div class="label">
          ITU Carrier-Code
        </div>
        <div>
          {{
            numberInfo.carrier !== null ? numberInfo.carrier.carrierCode : '-'
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhoneNumberCarrierDetails',
  props: {
    numberInfo: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped></style>
