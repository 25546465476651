<template>
  <DisplayContainer
    isSubcomponent
    label="Session Lookup"
    style="background-color: transparent"
  >
    <div>
      <div
        class="input-row-auto"
        style="max-width: 850px; grid-template-columns: 40% 40% 20%;"
      >
        <v-text-field
          ref="username"
          v-model="username"
          label="Username"
          placeholder="username@goetel"
          clearable
          autofocus
        />
        <v-text-field
          ref="ipv4"
          v-model="ipv4"
          label="IPv4"
          placeholder="127.0.0.1"
          clearable
          :rules="[ipv4Rule]"
        />
        <v-text-field
          ref="port"
          v-model="port"
          label="Port"
          type="number"
          min="1"
          max="65535"
          placeholder="1 - 65535"
          :rules="[portRule]"
        />
      </div>
      <div
        class="input-row-auto"
        style="max-width: 850px; grid-template-columns: 50% 50%;"
      >
        <v-text-field
          ref="ipv6"
          v-model="ipv6"
          label="IPv6"
          placeholder="::1"
          clearable
          :rules="[ipv6Rule]"
        />
        <v-menu
          ref="datePickMenu"
          v-model="datePickMenu"
          :close-on-content-click="false"
          :return-value.sync="dateRange"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Datum"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateRange"
            :max="new Date().toISOString()"
            show-adjacent-months
            range
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="datePickMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.datePickMenu.save(dateRange)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>
    <div>
      <v-data-table
        class="elevation-1"
        item-key="acctsessionid"
        :headers="tableHeaders"
        :items="tableFilteredSessions"
        :options.sync="tableOptions"
        :server-items-length="tableTotalFilteredSessions"
        :loading="tableLoading"
        dense
        :single-select="true"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 25, 50]
        }"
      >
        <template v-slot:[`item.sessionLockId`]="{ item }">
          <v-icon
            v-if="item.sessionLockId !== 0"
            title="Diese Session ist bereits gesperrt"
            color="warning"
          >
            mdi-lock-outline
          </v-icon>
          <v-icon
            v-else
            color="success"
            @click.stop="lockSession=item; lockSessionDialog = true"
          >
            mdi-lock-open-variant-outline
          </v-icon>
        </template>
        <template v-slot:[`item.acctStartTime`]="{ item }">
          {{ formatSessionDateTime(item.acctStartTime) }}
        </template>
        <template v-slot:[`item.acctStopTime`]="{ item }">
          {{ formatSessionDateTime(item.acctStopTime) }}
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-if="lockSession !== null"
      v-model="lockSessionDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Session {{ lockSession.radacctid }} sperren
        </v-card-title>

        <v-card-text class="mt-4">
          <v-text-field
            v-model="lockSession.radacctid"
            filled
            label="Session ID"
            type="number"
            disabled
          ></v-text-field>

          <v-text-field
            v-model="lockSessionData.ticketId"
            filled
            counter="7"
            label="ORTS Ticket ID"
            hint="Die ID aus der URL nach 'TicketID='"
            type="number"
          ></v-text-field>

          <v-textarea
            v-model="lockSessionData.comment"
            label="Kommentar"
            maxlength="2000"
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="warning"
            text
            :loading="lockSessionLoading"
            @click.stop="lockSessionRequest"
          >
            Sperren
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="lockSession = null; lockSessionDialog = false"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </DisplayContainer>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import { objectToEncodedQueryParamString } from '@/util/ObjectUtil.js';
import { ipv4IsValid, ipv6IsValid } from '@/util/ValidationUtil.js';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';

export default {
  name: 'RadiusSessionSearch',
  components: {
    DisplayContainer
  },
  data: () => ({
    username: null,
    ipv4: null,
    ipv6: null,
    port: null,
    dateRange: [],
    tableLoading: false,
    tableOptions: {},
    tableTotalFilteredSessions: 0,
    tableFilteredSessions: [],
    tableHeaders: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'sessionLockId'
      },
      {
        text: 'Username',
        align: 'start',
        sortable: false,
        value: 'username'
      },
      {
        text: 'Session Start',
        align: 'start',
        sortable: false,
        value: 'acctStartTime'
      },
      {
        text: 'Session Stop',
        align: 'start',
        sortable: false,
        value: 'acctStopTime'
      },
      {
        text: 'Delegated IPv6 Prefix',
        align: 'start',
        sortable: false,
        value: 'delegatedipv6prefix'
      },
      {
        text: 'IPv6 Route',
        align: 'start',
        sortable: false,
        value: 'framedipv6route'
      },
      {
        text: 'IPv6 Adresse',
        align: 'start',
        sortable: false,
        value: 'ipv6Address'
      },
      {
        text: 'IPv4 Adresse',
        align: 'start',
        sortable: false,
        value: 'ipv4Address'
      },
      {
        text: 'Portrange',
        align: 'start',
        sortable: false,
        value: 'portRangeBlock'
      }
    ],
    datePickMenu: false,
    lockSession: null,
    lockSessionDialog: false,
    lockSessionLoading: false,
    lockSessionData: null,
    debounceTimer: null,
    ipv4Rule: (value) => {
      return ipv4IsValid(value) || 'Ungültige IPv4 Adresse';
    },
    ipv6Rule: (value) => {
      return ipv6IsValid(value) || 'Ungültige IPv6 Adresse';
    },
    portRule: (value) => {
      const val = parseInt(value);
      return (
        value === '' ||
        value === null ||
        value === undefined ||
        (val >= 1 && val <= 65535) ||
        'Der Port muss zwischen 1 und 65535 sein'
      );
    }
  }),
  computed: {
    dateRangeText() {
      return this.dateRange.join(' ~ ');
    },
    currentUsername() {
      return this.$store.state.currentUsername;
    }
  },
  watch: {
    tableOptions: {
      handler() {
        this.lookupSessions();
      },
      deep: true
    },
    username() {
      this.fetchEntriesDebounced();
    },
    ipv4() {
      this.fetchEntriesDebounced();
    },
    ipv6() {
      this.fetchEntriesDebounced();
    },
    port() {
      this.fetchEntriesDebounced();
    },
    dateRange() {
      if (this.dateRange.length === 2) {
        if (new Date(this.dateRange[0]) > new Date(this.dateRange[1])) {
          this.dateRange.push(this.dateRange.shift());
        }
      }
      this.fetchEntriesDebounced(500);
    },
    lockSession() {
      if (this.lockSession === null) return;
      this.lockSessionData = {
        ticketId: null,
        comment: ''
      };
    }
  },
  created: function () {
    const now = new Date();
    const weekAgo = new Date();
    weekAgo.setDate(now.getDate() - 7);
    this.dateRange = [
      weekAgo.toISOString().substring(0, 10),
      now.toISOString().substring(0, 10)
    ];
    const hash = document.location.hash.substring(1);
    const hashParts = hash.split(';');
    if (hash.length === 0 || hashParts.length < 2) return;
    const searchParams = hashParts[1].split('&');
    searchParams.forEach((param) => {
      const [key, value] = param.split('=');
      console.log(param, key, value);
      switch (key) {
        case 'date_from': {
          this.dateRange[0] = value.substring(0, 10);
          break;
        }
        case 'date_to': {
          this.dateRange[1] = value.substring(0, 10);
          break;
        }
        case 'page': {
          this.tableOptions.page = Number.parseInt(value);
          break;
        }
        case 'pageSize': {
          this.tableOptions.itemsPerPage = Number.parseInt(value);
          break;
        }
        default: {
          this[key] = value;
          break;
        }
      }
    });
  },
  methods: {
    lookupSessions() {
      if (
        !this.$refs.username.validate() ||
        !this.$refs.ipv4.validate() ||
        !this.$refs.ipv6.validate() ||
        !this.$refs.port.validate()
      ) {
        return false;
      }

      const payload = {
        pageSize: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page
      };

      if (this.username) payload.username = this.username;
      if (this.ipv4) payload.ipv4 = this.ipv4;
      if (this.ipv6) payload.ipv6 = this.ipv6;
      if (this.port) payload.port = this.port;
      if (this.dateRange && Array.isArray(this.dateRange)) {
        if (this.dateRange.length === 2) {
          payload.date_from = this.dateRange[0];
          payload.date_to = this.dateRange[1];
        }
        if (
          this.dateRange.length === 1 ||
          this.dateRange[0] === this.dateRange[1]
        ) {
          payload.date_from = this.dateRange[0] + ' 00:00:00';
          payload.date_to = this.dateRange[0] + ' 23:59:59';
        }
      }

      const queryString = objectToEncodedQueryParamString(payload);

      this.tableLoading = true;

      HTTP.get('/provisioning/radius/session?' + queryString)
        .then(({ data, status }) => {
          if (status !== 200) {
            return;
          }
          this.tableTotalFilteredSessions = data.total;
          this.tableFilteredSessions = data.sessions;

          document.location.hash = `lookup;${queryString}`;
        })
        .catch((e) => {
          this.showErrorToast([
            'Fehler beim abrufen der RADIUS Daten',
            e.message
          ]);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    lockSessionRequest() {
      const payload = {
        ...this.lockSessionData,
        requestedBy: this.currentUsername
      };

      this.lockSessionLoading = true;
      HTTP.post(
        `/provisioning/radius/session/${this.lockSession.radacctid}/lock`,
        payload
      )
        .then(({ data, status }) => {
          if (status === 201) {
            this.lockSessionDialog = false;
            this.lockSession = null;
            this.lookupSessions();
            this.showToast(['Die Session wurde erfolgreich gesperrt'], 'info');
          }
        })
        .catch((e) => {
          this.showToast(['Fehler beim sperren der Session', e.message]);
        })
        .finally(() => {
          this.lockSessionLoading = false;
        });
    },
    fetchEntriesDebounced(timeout = 500) {
      if (this.tableLoading) return;
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.lookupSessions();
      }, timeout);
    },
    formatSessionDateTime(dateTime) {
      if (!dateTime) return;
      const date = new Date(dateTime);
      return date.toLocaleDateString('de-DE', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    },
    showToast(message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    }
  }
};
</script>

<style scoped>
.input-row-auto {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: var(--goe-spacing-1);
}
</style>
