<template>
  <div class="loader-wrapper">
    <Loader class="loader" />
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader.vue';

/**
 * This is a loader element that centers itself within the current element
 */
export default {
  components: {
    Loader
  }
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loader {
  margin: auto;
  top: calc(50% - var(--goe-spacing-2));
  position: initial;
}
</style>
