<template>
  <DisplayContainer
    isSubcomponent
    label="Gesperrte RADIUS Sessions"
    style="background-color: transparent"
  >
    <div class="mt-6">
      <v-data-table
        class="elevation-1"
        item-key="acctsessionid"
        :headers="tableHeaders"
        :items="tableFilteredSessions"
        :options.sync="tableOptions"
        :server-items-length="tableTotalFilteredSessions"
        :loading="tableLoading"
        dense
        :footer-props="{
          showFirstLastPage: true,
        }"
      >
        <template v-slot:[`item.ticketId`]="{ item }">
          <a
            :href="`https://otrs.goetel.de/ofork/index.pl?Action=AgentTicketZoom;TicketID=${ item.ticketId }`"
            target="_blank"
          >OTRS Ticket {{ item.ticketId }}</a>
        </template>
        <template v-slot:[`item.sessionId`]="{ item }">
          <a>
            <v-icon
              color="primary"
              style="cursor: pointer;"
              @click.stop="dialog = true; dialogSessionLock = item; changeComment = item.comment; changeTicketId = item.ticketId;"
            >
              mdi-magnify
            </v-icon>
          </a>
          Session ID {{ item.sessionId }}
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatSessionDateTime(item.createdAt) }}
        </template>
        <template v-slot:[`item.comment`]="{ item }">
          <span class="comment-short">{{ item.comment }}</span>
        </template>
      </v-data-table>
    </div>

    <v-dialog
      v-if="dialogSessionLock"
      v-model="dialog"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Session {{ dialogSessionLock.sessionId }} Infos
        </v-card-title>

        <v-card-text>
          <div class="pt-4">
            <v-textarea
              v-model="changeComment"
              label="Kommentar"
              maxlength="2000"
            ></v-textarea>

            <v-text-field
              v-model="changeTicketId"
              filled
              counter="7"
              label="ORTS Ticket ID"
              hint="Die ID aus der URL nach 'TicketID='"
              type="number"
            ></v-text-field>
            <dl class="dl-horizontal">
              <template v-for="(value, key) of dialogSessionLock.sessionData">
                <dt
                  v-if="key !== 'sessionLockId'"
                  :key="`key${key}`"
                >
                  <b>{{ key }}</b>
                </dt>
                <dd
                  v-if="key !== 'sessionLockId'"
                  :key="`val${key}`"
                >
                  {{ value ? value : '&nbsp;' }}
                </dd>
              </template>
            </dl>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="warning"
            text
            @click="confirmationDialog = true"
          >
            Session entsperren
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="saveSessionLock"
          >
            Speichern
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmationDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Bestätigung
        </v-card-title>

        <v-card-text>
          <p class="mt-9">
            Soll die Session wirklich entsperrt werden?
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="warning"
            text
            :loading="deletingRequest"
            @click="deleteSessionLock"
          >
            Ja
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="confirmationDialog = false"
          >
            Nein, abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </DisplayContainer>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import { objectToEncodedQueryParamString } from '@/util/ObjectUtil.js';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';

export default {
  name: 'RadiusSessionLockList',
  components: {
    DisplayContainer
  },
  data: () => ({
    dialog: false,
    confirmationDialog: false,
    dialogSessionLock: null,
    changeComment: null,
    changeTicketId: null,
    deletingRequest: false,
    tableLoading: false,
    tableOptions: {},
    tableTotalFilteredSessions: 0,
    tableFilteredSessions: [],
    tableHeaders: [
      {
        text: 'id',
        align: 'start',
        sortable: false,
        value: 'id'
      },
      {
        text: 'Gesperrt von',
        align: 'start',
        sortable: false,
        value: 'requestedBy'
      },
      {
        text: 'Ticket',
        align: 'start',
        sortable: false,
        value: 'ticketId'
      },
      {
        text: 'Session',
        align: 'start',
        sortable: false,
        value: 'sessionId'
      },
      {
        text: 'Gesperrt am',
        align: 'start',
        sortable: false,
        value: 'createdAt'
      },
      {
        text: 'Kommentar',
        align: 'start',
        sortable: false,
        value: 'comment'
      }
    ],
    debounceTimer: null
  }),
  computed: {},
  watch: {
    tableOptions: {
      handler() {
        this.lookupSessions();
      },
      deep: true
    }
  },
  created: function () {
    this.lookupSessions();
  },
  methods: {
    lookupSessions() {
      const payload = {
        pageSize: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page
      };

      const queryString = objectToEncodedQueryParamString(payload);

      this.tableLoading = true;

      HTTP.get('/provisioning/radius/session-lock?' + queryString)
        .then(({ data, status }) => {
          if (status !== 200) {
            return;
          }
          this.tableTotalFilteredSessions = data.total;
          this.tableFilteredSessions = data.sessionLocks;
        })
        .catch((e) => {
          this.showToast(
            ['Fehler beim abrufen der RADIUS Daten', e.message],
            'error'
          );
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    deleteSessionLock() {
      this.deletingRequest = true;

      HTTP.delete(
        '/provisioning/radius/session-lock/' + this.dialogSessionLock.id
      )
        .then(({ data, status }) => {
          this.showToast(
            [`Session ${this.dialogSessionLock.sessionId} wurde entsperrt`],
            'info'
          );
        })
        .catch((e) => {
          this.showToast(
            ['Fehler beim abrufen der RADIUS Daten', e.message],
            'error'
          );
        })
        .finally(() => {
          this.deletingRequest = false;
          this.confirmationDialog = false;
          this.dialogSessionLock = null;
          this.lookupSessions();
        });
    },
    saveSessionLock() {
      const payload = {
        comment: this.changeComment,
        ticketId: this.changeTicketId
      };

      HTTP.patch(
        `/provisioning/radius/session-lock/${this.dialogSessionLock.id}`,
        payload
      )
        .then(({ data, status }) => {
          this.showToast(
            [`Session Lock ${this.dialogSessionLock.id} wurde gespeichert`],
            'info'
          );
        })
        .catch((e) => {
          this.showToast(
            ['Fehler beim speichern des Session Lock', e.message],
            'error'
          );
        })
        .finally(() => {
          this.deletingRequest = false;
          this.confirmationDialog = false;
          this.dialogSessionLock = null;
          this.lookupSessions();
        });
    },
    formatSessionDateTime(dateTime) {
      if (!dateTime) return;
      const date = new Date(dateTime);
      return date.toLocaleDateString('de-DE', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    },
    showToast(message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    }
  }
};
</script>

<style scoped>
.input-row-auto {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: var(--goe-spacing-1);
}

.comment-short {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 500px;
  display: block;
}

.dl-horizontal dt {
  float: left;
  width: 130px;
  overflow: hidden;
  clear: left;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dl-horizontal dd {
  margin-left: 150px;
}
</style>
